import { Link } from "gatsby";
import React from "react";

import { responsiveTitle1 } from "./typography.module.css";
import styles from "./footer.module.css";

const Footer = () => (
  <footer className={styles.root}>
    <div className={styles.footerContactTeaser}>
      <h2 className={responsiveTitle1}>
        Interested? <span>Let's get in touch.</span>
      </h2>
      <a className={styles.contactMail} href="mailto:moin@maxfolke.de">
        moin@maxfolke.de
      </a>
      <p className={styles.represented}>
        Represented by{" "}
        <a href="https://gesas.net/directors/max-prothmann/" target="_blank">
          Gesas.Management
        </a>
      </p>
    </div>
    <nav className={styles.footerMenu}>
      <ul>
        <li>
          <a href="https://vimeo.com/user102017674" target="_blank">
            Vimeo
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/maxfolke_/" target="_blank">
            Instagram
          </a>
        </li>
      </ul>
      <ul>
        <li>
          <Link to="/imprint">Imprint</Link>
        </li>
        <li>
          <Link to="/privacy">Privacy</Link>
        </li>
      </ul>
      <ul>
        <li>
          <a href="tel:+4915901171337">+49 1590 1171337</a>
        </li>
        <li>
          <a href="mailto:moin@maxfolke.de">moin@maxfolke.de</a>
        </li>
      </ul>
    </nav>
  </footer>
);

export default Footer;
