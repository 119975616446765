import React from 'react'
import ReactPlayer from 'react-player'

function VideoPlayer (props) {
  return (
    <ReactPlayer
      url={props.url}
      light
      controls
      width={'100%'}
      config={{
        youtube: {
          playerVars: {rel: 0}
        }
      }}
    />
  )
}

export default VideoPlayer
